import React from "react";
import {GatsbyImage} from 'gatsby-plugin-image'

const LogoLoopItem = ({ imageData }) => {
  const ratio = imageData.width / imageData.height;
  let widthHeightClasses = 'h-14 w-20';
  if (ratio > 5) {
    widthHeightClasses = 'h-14 w-36';
  } else if (ratio > 4) {
    widthHeightClasses = 'h-14 w-32';
  } else if (ratio > 3) {
    widthHeightClasses = 'h-14 w-28';
  } else if (ratio > 2) {
    widthHeightClasses = 'h-14 w-24';
  }
  return (
    <GatsbyImage image={imageData} alt={""} objectFit={"contain"} className={`mx-16 ${widthHeightClasses}`} />
  )
};

export default LogoLoopItem;
