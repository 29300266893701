import * as React from "react";
import { graphql } from "gatsby";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {GatsbyImage} from 'gatsby-plugin-image'

import Layout from "../layouts/default-layout";
import PageHead from "../components/pageHead";
import Video from "../components/video";
import Callout from "../components/callout";
import TeaserCase from "../components/teaserCase";
import TeaserInsight from "../components/teaserInsight";
import TeaserExclusive from "../components/teaserExclusive";

import Marquee from "react-fast-marquee";
import LogoLoopItem from "../components/logoLoopItem";

// markup
const IndexPage = ({ path, data }) => {
  const intl = useIntl()
  
  const content = data.sanityHomepage;
  const title = content.title ? content.title[intl.locale] : '';
  const metaTitle = content.meta_title ? content.meta_title[intl.locale] : title;
  const metaDescription = content.meta_description ? content.meta_description[intl.locale] : content.subline ? content.subline[intl.locale] : null;  
  let mainblocksIndexCase = 1;
  let mainblocksIndexInsight = 1;

  const [showForm, setShowForm] = React.useState(true);
  const [msg, setMsg] = React.useState('');
  const submitNL = function (e, url) {
    e.preventDefault()
    const email = e.target.elements[0].value
    addToMailchimp(email, {}, url)
      .then(data => {
        setMsg(data.msg)
        setShowForm(false)
      })
      .catch(err => {
        console.error(err)
      })
  }
  
  return (
    <Layout mainClassName="bg-bold" title={metaTitle} description={metaDescription} noIndex={content.meta_noindex} path={path}>
      <PageHead
        singleDiamond={false}
        headline={content.slogan[intl.locale] || ''}
        subline={content.title[intl.locale] || ''}
      />

      {content.video !== "" && (
        <div className="mb-7 medium:mb-14">
          <Video vimeoURL={content.video} />
        </div>
      )}
      
      {content.mainblocks.map((el, index) => {
        if (el._type === 'caseSelect') {
          if (mainblocksIndexCase === 1) {
            mainblocksIndexCase = 2
            return (
              <div className="mb-7 medium:mb-24 tro-default-padding-x" key={index}>
                <TeaserCase
                  key={index}
                  item={el.case}
                  isBanner={true}
                  bannerProperties={{
                    color: "bg-brand-light",
                    isTextRight: false,
                  }}
                />
              </div>
            )
          } else if (mainblocksIndexCase === 2) {
            mainblocksIndexCase = 3
            return (
              <div className="mb-7 medium:mb-28 tro-default-padding-x" key={index}>
                <div className="tro-grid-responsive">
                  <div className="col-span-6 small:col-span-12 large:col-start-4 large:col-span-6">
                    <TeaserCase
                      key={index}
                      item={el.case}
                      bannerProperties={{
                        color: "bg-brand-light",
                        isTextRight: false,
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          } else if (mainblocksIndexCase === 3) {
            mainblocksIndexCase = 4
            return (
              <div className="mb-7 medium:mb-24 large:px-8" key={index}>
                <div className="tro-grid-responsive">
                  <div className="col-span-full">
                    <TeaserCase
                      key={index}
                      item={el.case}
                      isBanner={true}
                      bannerProperties={{
                        color: "bg-brand-lighter",
                        isTextRight: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          } else if (mainblocksIndexCase === 4) {
            mainblocksIndexCase = 1
            return (
              <div className="mb-7 medium:mb-28 tro-default-padding-x" key={index}>
                <div className="tro-grid-responsive">
                  <div className="col-span-6 small:col-span-12 large:col-start-4 large:col-span-6">
                    <TeaserCase
                      key={index}
                      item={el.case}
                      bannerProperties={{
                        color: "bg-brand-light",
                        isTextRight: false,
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          }
        } else if (el._type === 'insightSelect') {
          if (mainblocksIndexInsight === 1) {
            mainblocksIndexInsight = 2
            return (
              <div className="mb-7 medium:mb-24" key={index}>
                <div className="tro-default-padding-x">
                  <div className="tro-grid-responsive">
                    <div className="col-span-10">
                      <TeaserInsight
                        key={index}
                        item={el.insight}
                        customStyle="left"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          } else if (mainblocksIndexInsight === 2) {
            mainblocksIndexInsight = 3
            return (
              <div className="mb-7 medium:mb-24" key={index}>
                <div className="tro-default-padding-x">
                  <div className="tro-grid-responsive">
                    <div className="col-span-10 col-start-3">
                      <TeaserInsight
                        key={index}
                        item={el.insight}
                        customStyle="right"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        } else if (el._type === 'calloutHomepageBlock') {
          return (
            <div className="mb-7 medium:mb-24 tro-default-padding-x" key={index}>
              <Callout
                headline={el.headline && el.headline[intl.locale] ? el.headline[intl.locale] : null}
                subline={el.body && el.body[intl.locale] ? el.body[intl.locale] : null}
              />
            </div>
          )
        } else if (el._type === 'exclusiveSelect') {
          return (
            <div className="mb-7 medium:mb-24 large:px-8" key={index}>
            <div className="tro-grid-responsive">
              <div className="col-span-full">
                <TeaserExclusive
                  key={index}
                  item={el.exclusive}
                  isBanner={true}
                />
              </div>
            </div>
          </div>
          )
        } else if (el._type === 'newsletterBlock') {
          return (
            <div className={`mb-7 medium:mb-24 col-span-full bg-brand-lighter tro-grid-responsive `} key={index}>
              <div className="col-start-1 col-span-full tablet:col-start-4 tablet:col-span-6">
                <div className="px-4 py-4 lg:px-0 medium:py-16">
                  {el.title && (
                    <h4 className="text-base font-black text-center medium:text-2xl">{el.title}</h4>
                  )}
                  {el.subtitle && (
                    <p className="text-center">{el.subtitle}</p>
                  )}              
                  <div className="mt-6 text-center medium:mt-12">
                    {showForm && (
                      <form action="/" method="post" onSubmit={(e)=>submitNL(e, el.url)}>
                        <label className="block pb-0 font-mono text-xs opacity-50 medium:pb-1 text-dark medium:text-sm" htmlFor="nlemailinput"><small>E-Mail</small></label>
                        <div className="flex flex-col">
                          <input className="flex-grow px-4 py-2 text-xs text-center text-black border-gray-400 rounded-full outline-none medium:text-sm border-1 focus:border-black" type="email" name="email" id="nlemailinput" required />
                          <button className="self-center flex-none mt-2 ml-0 text-xs tro-button-cta medium:ml-4 medium:text-sm" type="submit p-2 rounded-lg ">{ intl.formatMessage({ id: "submit" }) }</button>
                        </div>
                      </form>
                    )}
                    
                    {!showForm && (
                      <p className="block px-10 py-4 text-black rounded-full bg-brand tro-newsletter-message" dangerouslySetInnerHTML={{ __html: msg }}></p>
                    )}
                    
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })}
      
      
      {false && (
        <div className="mb-9 medium:mb-24 tro-default-padding-x">
          <Callout
            subline="Wir verbinden Marken mit kultureller Relevanz. International und lorem ipsum dolor sit amet."
            ctaLink="#"
          />
        </div>
      )}
      
      <div className="hidden mb-28 medium:block">
        <Marquee className="mb-16" speed="55" direction="right" gradient={false}>
          {content.logos1.map((item, index) => (
            <LogoLoopItem key={`${index}-${item.asset.id}`} imageData={item.asset.gatsbyImageData} />
          ))}
        </Marquee>
        <Marquee className="" speed="55" gradient={false}>
          {content.logos2.map((item, index) => (
            <LogoLoopItem key={`${index}-${item.asset.id}`} imageData={item.asset.gatsbyImageData} />
          ))}
        </Marquee>
      </div>

      <div className="block mb-14 medium:hidden">
        <Marquee className="mb-8" speed="30" direction="right" gradient={false}>
          {content.logos1.map((item, index) => (
            <LogoLoopItem key={`${index}-${item.asset.id}`} imageData={item.asset.gatsbyImageData} />
          ))}
        </Marquee>
        <Marquee className="" speed="30" gradient={false}>
          {content.logos2.map((item, index) => (
            <LogoLoopItem key={`${index}-${item.asset.id}`} imageData={item.asset.gatsbyImageData} />
          ))}
        </Marquee>
      </div>

    </Layout>
  );
};

export default IndexPage;



export const query = graphql`
query HomepagePage {
  sanityHomepage {
    slogan {
      de
      en
    }
    title {
      de
      en
    }
    meta_title {
      de
      en
    }
    meta_description {
      de
      en
    }
    meta_noindex
    video
    mainblocks {
      ... on SanityCalloutHomepageBlock {
        _type
        headline {
          de
          en
        }
        body {
          de
          en
        }
      }
      ... on SanityCaseSelect {
        _type
        case {
					... Case
        }
      }
      ... on SanityInsightSelect {
        _type
        insight {
					... Insight
        }
      }
      ... on SanityExclusiveSelect {
        _type
        exclusive {
					... Exclusive
        }
      }
      ... on SanityNewsletterBlock {
        _type
        title
        subtitle
        url
      }
    }
    logos1 {
      asset {
        id
        gatsbyImageData(height: 400, width: 400, fit: CLIP, backgroundColor: "#ffffff", placeholder: NONE)
      }
    }
    logos2 {
      asset {
        id
        gatsbyImageData(height: 400, width: 400, fit: CLIP, backgroundColor: "#ffffff", placeholder: NONE)
      }
    }
  }
}
`;

